@tailwind base;
@tailwind components;
@tailwind utilities;

@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-notifications/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

  @layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  @keyframes infinite-scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  .custom-radial-gradient {
    background: radial-gradient(circle, white, #e5e7eb);
  }
  
  .animate-infinite-scroll {
    display: flex;
    width: 400%; /* Width of the container should be twice the viewport width */
    animation: infinite-scroll 20s linear infinite;
  }
  
  .bg-gradient-to-r {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }
  
  .bg-gradient-to-l {
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  }

  .leaflet-container {
      height: 100%;
      width: 100%;
  }

  .leaflet-touch .leaflet-draw-actions a, .leaflet-touch .leaflet-control-toolbar .leaflet-toolbar-1 > li > .leaflet-toolbar-icon {
    font-size: 11px;
    line-height: 70px;
    height: 70px;
    color: black;
    background-color: lightblue;
  }
  /* .leaflet-touch .leaflet-draw-actions, .leaflet-touch .leaflet-toolbar-1 {
    left: 36px;
  } */
  
  .leaflet-touch .leaflet-bar a, .leaflet-touch .leaflet-toolbar-0 > li > a {
    width: 55px;
    height: 55px;
    font-size: 10px;
    line-height: 25px;
    background-size: 600px 60px;
    /* background-size: auto; */
    /* background-image: url('./assets/pen.gif'); */
    /* background-position-x: 100px; */
    /* background-size: contain; */
  }
  .leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
    background-position: -60px -3px;
  }

  .leaflet-touch .leaflet-draw-toolbar .leaflet-draw-draw-circle {
    background-position: -182px -3px;
  }

  .leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove {
    background-position: -362px -3px;
  }

  .leaflet-touch .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
    background-position: -362px -3px;
  }

  .leaflet-draw-toolbar .leaflet-draw-edit-remove.leaflet-disabled {
    background-position: -362px -3px;
  }

  .leaflet-touch .leaflet-draw-actions a, .leaflet-touch .leaflet-control-toolbar .leaflet-toolbar-1 > li > .leaflet-toolbar-icon {
    font-size: 10px;
    line-height: 25px;
    height: 25px;
  }
  .leaflet-touch .leaflet-draw-actions, .leaflet-touch .leaflet-toolbar-1 {
    left: 58px;
  }

.leaflet-control-zoom-in:before,
.leaflet-control-zoom-out::before {
  width: 50px;
  height: 50px;
	text-indent: 1px;
  font-size: xx-large;
}

.leaflet-editing-icon.leaflet-div-icon,
.leaflet-drawing-icon.leaflet-div-icon,
.leaflet-geoman-handle,
.pm-handle {
  width: 40px !important;
  height: 40px !important;
  border-radius: 100%;
  /* Other styles as needed */
}

.marker-icon { 
   background-color: #ffffff; 
   border: 1px solid #3388ff; 
   border-radius: 50%; 
   margin: -10px 0 0 -10px !important; 
   width: 16px !important; 
   height: 16px !important; 
   outline: 0; 
   transition: opacity ease 0.3s; 
}

.marker-icon:focus { 
   background-color: #ffffff; 
   border: 1px solid #3388ff; 
   border-radius: 50%; 
   margin: -14px 0 0 -14px !important; 
   width: 28px !important; 
   height: 28px !important; 
    outline: 0; 
 }

 .typewriter, .Typewriter__cursor {
  /* font-size: 24px; */
  font-weight: 100;
  background-color: rgb(249, 115, 22, 0.15);
  color: rgb(249 115 22);
  /* background-color: rgb(255 237 213); */
 }

 @media (width <= 600px) {
  .typewriter, .Typewriter__cursor {
    font-size: 18px;
   }
  }

  @media (width > 600px) {
    .typewriter, .Typewriter__cursor {
      font-size: 24px;
     }
  }
